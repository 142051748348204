import index from '@/views/options_center/index'
export default [{
  path: '/options',
  name: 'options',
  label: '配置中心',
  index: 0,
  component: index,
  redirect: '/options/platform/saler',
  children: [{
    path: '/options/platform',
    name: 'options_platform',
    label: '平台封禁管理',
    index: 1,
    component: () => import(`@/views/options_center/platform/index`),
    children:[{
      path: '/options/platform/saler',
      name: 'options_platform_saler',
      label: '卖家封禁',
      index: 2,
      component: () => import(`@/views/options_center/platform/saler`),
    },{
      path: '/options/platform/goods',
      name: 'options_platform_goods',
      label: '商品封禁',
      index: 2,
      component: () => import(`@/views/options_center/platform/goods`),
    },{
      path: '/options/platform/category',
      name: 'options_platform_category',
      label: '品类封禁',
      index: 2,
      component: () => import(`@/views/options_center/platform/category`),
    },{
      path: '/options/platform/white_list',
      name: 'options_platform_white_list',
      label: '商品白名单',
      index: 2,
      component: () => import(`@/views/options_center/platform/white_list`)
    }]
  },{
    path: '/options/site',
    name: 'options_site',
    label: '站点配置',
    index: 1,
    component: () => import(`@/views/options_center/site/index`),
    children: [{
      path: '/options/site/options',
      name: 'options_site_options',
      label: '解析站功能配置',
      index: 2,
      component: () => import(`@/views/options_center/site/options`),
    }]
  },{
    path: '/options/fee',
    name: 'options_fee',
    label: '手续费管理',
    index: 1,
    component: () => import(`@/views/options_center/fee/index`),
    children: [{
      path: '/options/fee/goods_fee',
      name: 'options_goods_fee',
      label: '商品手续费管理',
      index: 2,
      component: () => import(`@/views/options_center/fee/goods_fee`),
    },
    {
      path: '/options/fee/goods_fee_record',
      name: 'options_goods_fee_record',
      label: '操作记录',
      hideThis: true,
      index: 2,
      component: () => import(`@/views/options_center/fee/history`),
    }]
  },{
    path: '/options/client',
    name: 'options_client',
    label: '客户端配置',
    index: 1,
    component: () => import(`@/views/options_center/client_options/index`),
    children: [{
      path: '/options/client/cash_register',
      name: 'options_client_cash_register',
      label: '固定参数配置',
      index: 2,
      component: () => import(`@/views/options_center/client_options/options/list`),
    }]
  }
]
}]