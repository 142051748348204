<template>
  <div class="home">
    <div class="content">
      <Sider class="left" />
      <div class="right">
        <Header />
        <breadcrumb v-if="flag"></breadcrumb>
        <router-view class="right-content" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/views/Header.vue'
import Sider from '@/views/Sider.vue'
// @ is an alias to /src
export default {
  name: 'Home',
  data() {
    return {
      flag: true
    }
  },
  components: {
    Header,
    Sider
  },
  mounted() {
    const arr = ['CouponChild','codeDetail']
    const name = this.$route.name
    this.flag = !arr.includes(name)
  }
}
</script>
<style lang="scss" scoped>
.home{
  // display: flex;
  // flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content{
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .left{
      min-width: 240px;
      flex-shrink: 0;
      height: 100%;
      background-color: $homeSiderColor;
      overflow: auto;
    }
    .right{
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      .breadcrumb{
        flex-shrink: 0;
      }
    }
    .right-content{
      position: relative;
      height: 100%;
      width: 100%;
      padding: 18px 24px;
      padding-bottom: 50px;
      text-align: left;
      // padding-top: 0;
      overflow: auto;
      // display: flex;
      // flex-direction: column;
    }
  }
}
</style>