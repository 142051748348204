import axios from 'axios'
import store from './store'
import {Message, Loading} from 'element-ui'
let loading = null
axios.interceptors.request.use(config=>{
  loading = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0.4)'
  });
  if(!config.url.includes('http')) {
    config.url = store.state.CMS.url + config.url
  }
  const token = 'Bearer '+ window.localStorage.getItem('loginToken')
  if(token && !config.headers.Authorization) {
    config.headers.Authorization = token
  }
  return config
}),
error=>{
  return Promise.error(error);
}

axios.interceptors.response.use(
  response => {
    loading.close()
    if (response.status === 200 && ([10000, 1000, 0].includes(response.data.code))) {
      return Promise.resolve(response.data);
    } else if (response.status === 200 && response.data.code != 10008) {
      Message.error(response.data.msg)
      return Promise.reject(response.data.msg)
    } else if(response.status == 200 && response.data.code == 10008) {
      localStorage.clear()
      setTimeout(function(){
        location.reload()
      },1000)
      Message.error(response.data && response.data.msg || '请求失败')
      return Promise.reject(response.data && response.data.msg || '请求失败');
    } else {
      Message.error(response.data || response)
      return Promise.reject(response.data || response);
    }
  },
  error => {
    loading.close()
    Message.error(error)
    return Promise.reject(error);
  }
);

export default axios