<template>
  <div class="content-top">
    <slot>
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(item, index) in list" :key="index">{{
          item.label
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </slot>
  </div>
</template>
<script>
export default {
  computed: {
    list() {
      return this.$store.state.CMS.breadcrumb;
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  color: #666;
  font-size: 14px;
  padding: 23px 20px;
  border-bottom: 1px solid $lineColor;
}
</style>