<template>
  <div v-if="$store.state.common.childWindow.show" class="child-window">
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root }) {
    const open = () => {
      root.$store.commit('childWindow', {show: true, data: {}})
    }
    const close = () => {
      root.$store.commit('childWindow', {show: false, data: {}})
    }
    return {
      open,
      close
    }
  },
})
</script>
<style lang="scss" scoped>
.child-window{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: #fff;
}
</style>