import Home from '../views/Home.vue'
import CMS from './CMS'
import market from './marketCenter'
import risk from './riskManager'
import options from './options'
import user from './userCenter'
import login from '../views/Login'
import goods_order from './goods_order'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [...CMS, ...market,...risk, ...options, ...user, ...goods_order],
    redirect: '/CMS'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  }
]
export default routes