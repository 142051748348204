<template>
  <div class="user-block">
      <!-- <img class="avatar" src="" /> -->
    <span>{{userName || ''}}</span>
    <el-button class="login-btn" size="mini" @click="loginout">登出</el-button>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  setup(prop, { root }) {
    const userName = ref( localStorage.getItem('userName') ||'')
    const loginout = async () => {
      root.$axios.post('/login_out')
      .then(()=>{
        userName.value = ''
        localStorage.clear()
        // root.$axios.headers.Authorization = undefined
        root.$router.push("/login");
      })
    };
    return {
      userName,
      loginout,
    };
  },
});
</script>
<style lang="scss" scoped>
.user-block {
  display: flex;
  align-items: center;
  font-size: 16px;
  aside{
      display: flex;
      align-items: center;
      span{
          margin-right: 10px;
      }
  }
}
.avatar {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 10px;
  object-fit: contain;
}
.login-btn {
  width: 50px !important;
  height: 26px;
}
</style>
