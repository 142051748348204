<template>
  <div class="coupon-detail">
    <div class="top">
      <aside>
        <span @click="$router.back()"><i class="el-icon-back"></i>返回</span>
        <div>
          <p>优惠券组：{{$route.query.groupName}}</p>
          <p>优惠券名：{{$route.query.name}}</p>
        </div>
      </aside>
      <el-button type="danger" @click="disableCoupon">强制失效</el-button>
    </div>
    <header class="header">
      <div>
        <span>用户id：</span>
        <el-input v-model="params.user_id" placeholder="请输入用户id查询"></el-input>
      </div>
      <div>
        <span>状态：</span>
        <el-select v-model="params.status">
          <el-option value="1" label="未使用"></el-option>
          <el-option value="2" label="未生效"></el-option>
          <el-option value="3" label="已失效"></el-option>
          <el-option value="4" label="已使用"></el-option>
        </el-select>
      </div>
      <div>
        <span>获取方式：</span>
        <el-select v-model="params.source_type">
          <el-option value="1" label="活动领取"></el-option>
          <el-option value="2" label="指定发放"></el-option>
          <el-option value="3" label="全站发放"></el-option>
          <el-option value="4" label="兑换码"></el-option>
          <el-option value="5" label="退款发放"></el-option>
        </el-select>
      </div>
      <el-button @click="clear">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </header>
    <DragTable :list-query="list" :header="header" :sort="false">
      <template #createtime="{ row }">
        <span>{{
          $dayjs(row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")
        }}</span>
      </template>
      <template #usedtime="{ row }">
        <span>{{
          row.used_at > 0 ? $dayjs(row.used_at * 1000).format("YYYY-MM-DD HH:mm:ss") : '-'
        }}</span>
      </template>
      <template #control="{row}">
        <span v-if="row.is_force_invalid" class="red" @click="cancel(row)">强制失效</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-button type="primary" @click="exportFile">导出</el-button>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  setup(props,{ root }) {
    const total = ref(10)
    const params = reactive({
      user_id: undefined,
      status: undefined,
      source_type: undefined,
      is_show_page: 1,
      page: 1,
      per_page: 10
    })
    const header = [
      { name: "子券id", value: "coupon_user_id", width: 100 },
      { name: "UID", value: "user_id", width: 100 },
      { name: "用户名", value: "user_name" },
      { name: "获取方式", value: "source_type_text" },
      { name: "领取时间", value: "created_at", width: 180, slot: 'createtime' },
      { name: "使用时间", value: "used_at", width: 180, slot: 'usedtime' },
      { name: "操作人", value: "admin_name", width: 70 },
      { name: "状态", value: "status_text", width: 110 },
      { name: "关联支付单", value: "trade_id" },
      { name: "创建人", value: "admin_name" },
      { name: "操作", value: "", slot: "control", width: 212 },
    ];
    const list = reactive({data:[]});
    const clear = () => {
      for(let key in params) {
        params[key] = undefined
      }
      params.is_show_page = 1
      params.page = 1
      params.per_page = 10
      getList()
    }
    const cancel = async (data) => {
      if(!data.is_force_invalid) {
        root.$message.warning('该状态不可强制失效')
        return
      }
      const confirm = await root.$goDialog('确定要强制失效 '+data.user_name+' 的优惠券吗？')
      if(!confirm) return
      root.$axios.put(`/coupons/couponUsers/${data.coupon_user_id}/forceInvalid`)
      .then(res=>{
        if(res.code === 1000) {
          root.$message.success(res.msg)
          getList()
        }
      })
      
    }
    const getList = ()=>{
      try {
        root.$axios.get('/coupons/coupons/'+root.$route.query.id, {
          params
        })
        .then(res=>{
          list.data = res.data.result
          total.value = res.data.pagination.total
        })
      } catch (error) {
        console.error(error)
      }
    }
    getList()
    const disableCoupon = async () => {
      const confirm = await root.$goDialog('是否失效全部优惠券？！')
      if(!confirm) return
      const confirm2 = await root.$goDialog('请再次确认是否失效全部优惠券？！')
      if(!confirm2) return
      try {
        root.$axios.put('/coupons/coupons/'+root.$route.query.id+'/forceInvalidAll')
        .then(res=>{
          if(res.code === 1000) {
            root.$message.success(res.msg)
            getList()
          } else {
            root.$message.error(res.msg)
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
    const exportFile = () => {
      root.$axios.get(`/coupons/coupons/${root.$route.query.id}/exportDetail`, {
        params: {
          user_id: params.user_id,
          status: params.status
        }
      })
      .then(res=>{
        const a = window.document.createElement('a')
        a.href= root.$store.state.CMS.url+res.data.file_path
        a.click()
      })
      .catch(error=>{
        root.$message.error(error)
      })
    }
    const search = () => {
      params.page = 1
      getList()
    }
    const handleSizeChange = (size) => {
      params.per_page = size
      params.page = 1
      getList()
    }
    const handleCurrentChange = (index) => {
      params.page = index
      getList()
    }
    return {
      total,
      params,
      list,
      header,
      open,
      clear,
      cancel,
      search,
      getList,
      disableCoupon,
      exportFile,
      handleSizeChange,
      handleCurrentChange
    }
  },
})
</script>
<style lang="scss" scoped>
.top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #f4f5fb;
  margin-bottom: 16px;
  aside{
    font-size: 18px;
    color: $mainColor;
    display: flex;
    align-items: center;
    span{
      margin-right: 15px;
      cursor: pointer;
    }
    p{
      font-size: 14px;
      color: #666;
    }
  }
}
.coupon-detail{
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    width: 100%;
    &>div{
      display: flex;
      align-items: center;
      margin-right: 15px;
      &>span{
        font-size: 14px;
        white-space: nowrap;
        text-align: right;
        flex-shrink: 0;
      }
      &.input{
        width: 37%;
      }
    }
  }
}
.red{
  color: $dangerColor;
  cursor: pointer;
}
.table-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
</style>