import Index from '@/views/marketCenter/Index'

export default [{
  path: '/risk',
  name: 'risk',
  label: '风控中心',
  index: 0,
  redirect: '/risk/user',
  component: Index,
  children: [{
    path: '/risk/user',
    name: 'risk_user',
    icon: '/images/icon/icon1',
    label: '用户管理',
    index: 1,
    redirect: '/risk/user/userName',
    component: ()=> import(`@/views/riskManager/userManager/Index`),
    children: [
      {
        path: '/risk/user/userName',
        name: 'userName',
        label: '用户昵称',
        index: 2,
        component: ()=> import(`@/views/riskManager/userManager/userName.vue`)
      },{
        path: '/risk/user/userSearch',
        name: 'userSearch',
        label: '用户搜索',
        index: 2,
        component: ()=> import(`@/views/riskManager/userManager/userSearch.vue`)
      },{
        path: '/risk/user/complaints',
        name: 'complaints',
        label: '商品举报',
        index: 2,
        component: ()=> import(`@/views/riskManager/userManager/complaints.vue`)
      },{
        path: '/risk/user/sale_complaints',
        name: 'sale_complaints',
        label: '商家举报',
        index: 2,
        component: ()=> import(`@/views/riskManager/userManager/sale_complaints.vue`)
      }
    ]
  },{
    path: '/risk/goods',
    name: 'risk_goods',
    icon: '/images/icon/icon3',
    label: '商品管理',
    index: 1,
    // redirect: '/risk/user/goods',
    component: ()=> import(`@/views/riskManager/goodsManager/index.vue`),
    children: [
      // {
      //   path: '/risk/goods/word',
      //   name: 'risk_goods_word',
      //   label: '文字管理',
      //   index: 2,
      //   component: ()=> import(`@/views/riskManager/goodsManager/word.vue`)
      // },
      {
        path: '/risk/goods/pictrue',
        name: 'risk_goods_pictrue',
        label: '图片管理',
        index: 2,
        component: ()=> import(`@/views/riskManager/goodsManager/image.vue`),
      },{
        path: '/risk/goods/pictrue/history',
        name: 'risk_goods_pictrue_hisory',
        label: '图片审核记录',
        index: 2,
        hideThis: true,
        component: ()=> import(`@/views/riskManager/goodsManager/components/history.vue`)
      },
      {
        path: '/risk/goods/list_title',
        name: 'risk_goods_list_title',
        label: '列表标题管理',
        index: 2,
        component: ()=> import(`@/views/riskManager/goodsManager/list_title.vue`)
      },
      {
        path: '/risk/goods/goods_detail',
        name: 'risk_goods_goods_detail',
        label: '商品详情管理',
        index: 2,
        component: ()=> import(`@/views/riskManager/goodsManager/goods_detail.vue`)
      },
    ]
  },{
    path: '/risk/words',
    name: 'risk_words',
    icon: '/images/icon/icon7',
    label: '词库管理',
    index: 1,
    // redirect: '/risk/user/goods',
    component: ()=> import(`@/views/riskManager/wordsManager/index.vue`),
    children: [
      {
        path: '/risk/words/list',
        name: 'risk_words_list',
        label: '词库列表',
        index: 2,
        component: ()=> import(`@/views/riskManager/wordsManager/assigned.vue`)
      },
      {
        path: '/risk/words/test',
        name: 'risk_words_test',
        label: '词库测试',
        index: 2,
        hideThis: true,
        component: ()=> import(`@/views/riskManager/wordsManager/test.vue`)
      }
    ]
  },{
    path: '/risk/wordsDetail',
    name: 'risk_words_detail',
    label: '词库管理',
    hideThis: true,
    index: 1,
    // redirect: '/risk/user/goods',
    component: ()=> import(`@/views/riskManager/wordsManager/detail.vue`)
  },{
    path: '/risk/word_history',
    name: 'risk_words_history',
    label: '删除记录',
    hideThis: true,
    index: 1,
    // redirect: '/risk/user/goods',
    component: ()=> import(`@/views/riskManager/wordsManager/history.vue`)
  },{
    path: '/risk/setting',
    name: 'risk_setting',
    label: '其他设置',
    icon: '/images/icon/icon2',
    index: 1,
    component: ()=> import(`@/views/riskManager/setting.vue`)
  }]
}]