<template>
  <div>
    <header class="header-flex">
      <div class="item">
        <span>UID：</span>
        <el-input v-model="params.user_id" placeholder="请输入用户id"></el-input>
      </div>
      <div class="item">
        <span>用户名：</span>
        <el-input v-model="params.nickname" placeholder="请输入用户名"></el-input>
      </div>
      <div class="item">
        <span>状态：</span>
        <el-select v-model="params.status">
          <el-option value="" label="全部"></el-option>
          <el-option value="1" label="待操作"></el-option>
          <el-option value="2" label="已取消"></el-option>
          <el-option value="3" label="已驳回"></el-option>
          <el-option value="4" label="已注销"></el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="getList">查询</el-button>
    </header>
    <DragTable :header="header" :list-query="list">
      <template #status="{ row }">{{ state[row.status] }}</template>
      <template #ctl="{ row }">
        <span v-if="row.status == 1" class="word-color red" @click="cancellation(row,1)">立即注销</span>
        <span v-if="row.status == 1" class="word-color" @click="ctl(row,2)">驳回</span>
        <span v-if="row.status == 3" class="word-color" @click="ctl(row,3)">驳回理由</span>
      </template>
    </DragTable>
    <go-dialog v-model="visible">
      <div>
        <h3>驳回理由：</h3>
        <textarea class="reason" v-model="reason.reject_reason" placeholder="请输入驳回理由"></textarea>
      </div>
      <footer class="table-footer">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
    <go-dialog v-model="visible2">
      <div>
        <h3>驳回理由：</h3>
        <p style="border: 1px solid;padding: 20px 10px;">{{reject_reason}}</p>
      </div>
    </go-dialog>
    <footer class="table-footer">
      <span></span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      visible2: false,
      state: {
        1: "待操作",
        2: "已取消",
        3: "已驳回",
        4: "已注销",
      },
      params: {
        uid: "",
        name: "",
        state: "",
        page: 1,
        per_page: 10,
        total: 0,
      },
      reason: {
        user_destroy_id: '',
        status: '',
        reject_reason: ''
      },
      reject_reason: '',
      header: [
        { name: "UID", value: "user_id" },
        { name: "用户名", value: "nickname" },
        { name: "注销原因", value: "destroy_reason" },
        { name: "申请注销时间", value: "apply_time" },
        { name: "已申请天数", value: "apply_duration_days" },
        { name: "状态", slot: "status" },
        { name: "操作人", value: "operator" },
        { name: "操作", slot: "ctl" },
      ],
      list: {
        data: [],
      },
    };
  },
  watch: {
    visible2(val) {
      if(!val) {
        this.reject_reason = ''
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios
        .get(`/accountDestroy/index`, {
          params: this.params,
        })
        .then((res) => {
          this.list.data = res.data.result;
          this.params.total = res.data.pagination.total
        });
    },
    async cancellation(data) {
      const confirm = await this.$goDialog("是否确定要注销此账号");
      if (!confirm) return;
      this.$axios
        .post(`/accountDestroy/update`, {
          user_destroy_id: data.user_destroy_id,
          status: 4
        })
        .then((res) => {
          this.$message.success(res.msg);
          this.getList();
        })
    },
    ctl(data,type) {
      if(type == 2) {
        this.reason.user_destroy_id = data.user_destroy_id
        this.reason.status = 3
        this.visible = true
      }
      if(type == 3) {
        this.reject_reason = data.reject_reason
        this.visible2 = true
      }
    },
    cancel() {
      this.visible = false
      for(let key in this.reason) {
        this.reason[key] = ''
      }
    },
    confirm() {
      if(!this.reason.reject_reason) {
        this.$message.warning('请输入驳回理由')
        return
      }
      if(this.reason.reject_reason && !this.reason.reject_reason.trim()) {
        this.$message.warning('请输入驳回理由')
        return
      }
      this.$axios.post(`/accountDestroy/update`, this.reason)
      .then(res=>{
        this.$message.success(res.msg)
        this.cancel()
        this.getList()
      })
      
    },
    reset() {
      Object.keys(this.params).forEach(key=>{
        this.params[key] = ''
      })
      this.params.page = 1
      this.params.per_page = 10
      this.params.total = 0
      this.getList()
    },
    handleSizeChange(val) {
      this.params.per_page = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getList()
    }
  },
};
</script>
<style lang="scss" scoped>
.word-color{
  margin-right: 10px;
}
.reason{
  width: 100%;
  height: 150px;
  padding: 10px;
}
</style>