import Index from '@/views/goods_order_manager/index.vue'

export default [{
  path: '/goods_order_manager',
  name: 'goods_order_manager',
  label: '商品/订单管理',
  index: 0,
  redirect: '/goods_order_manager/orderManager',
  component: Index,
  children: [{
    path: '/goods_order_manager/orderManager',
    name: 'orderManager',
    label: '订单管理',
    index: 1,
    redirect: '/goods_order_manager/orderManager/yahoo_order_list',
    component: ()=> import(`@/views/goods_order_manager/yahoo/index.vue`),
    children: [
      {
        path: '/goods_order_manager/orderManager/yahoo_order_list',
        name: 'yahoo_order_list',
        label: '雅虎订单查询',
        index: 2,
        component: ()=> import(`@/views/goods_order_manager/yahoo/orderList.vue`)
      },
      {
        path: '/goods_order_manager/orderManager/yahoo_order_record',
        name: 'yahoo_order_record',
        label: '出价/操作记录',
        index: 2,
        hideThis: true,
        component: ()=> import(`@/views/goods_order_manager/yahoo/orderRecord.vue`)
      },
    ]
  },{
    path: '/goods_order_manager/user_manager/accountManager',
    name: 'accountManager',
    label: '账号管理',
    index: 1,
    redirect: '/goods_order_manager/user_manager/yahoo_order_list',
    component: ()=> import(`@/views/goods_order_manager/yahoo/index.vue`),
    children: [
      {
        path: '/goods_order_manager/user_manager/yahoo_order_list',
        name: 'yahoo_user_list',
        label: '日拍账号列表',
        index: 2,
        component: ()=> import(`@/views/goods_order_manager/yahoo/userManager.vue`)
      },
      {
        path: '/goods_order_manager/user_manager/yahoo_seller_black',
        name: 'yahoo_seller_black',
        label: '卖家黑名单',
        index: 2,
        hideThis: true,
        component: ()=> import(`@/views/goods_order_manager/yahoo/black.vue`)
      },
    ]
  }]
}
]