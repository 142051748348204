<template>
  <header class="header">
    <aside>
      <el-menu
        :default-active="headerIndex"
        mode="horizontal"
        class="el-menu-demo"
        background-color="#fff"
        text-color="#666"
        active-text-color="#4F5BFF"
        @select="handleSelect"
      >
        <el-menu-item index="CMS">CMS系统</el-menu-item>
        <el-menu-item index="market">营销中心</el-menu-item>
        <el-menu-item index="goods_order_manager">商品/订单管理</el-menu-item>
        <el-menu-item index="risk">风控中心</el-menu-item>
        <el-menu-item index="options">配置中心</el-menu-item>
        <el-menu-item index="userCenter">用户中心</el-menu-item>
      </el-menu>
    </aside>
    <aside><User></User></aside>
  </header>
</template>
<script>
import User from "@/components/User";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { User },
  setup(prop, { root }) {
    const headerIndex = ref( root.$store.state.CMS.topIndex || root.$route.name);
    const handleSelect = (key) => {
      headerIndex.value = key
      root.$store.state.CMS.topIndex = key
      root.$router.push({name: key})
    };
    watch(
      () => root.$route,
      () => {
        headerIndex.value = root.$store.state.CMS.topIndex;
      }
    );
    return {
      headerIndex,
      handleSelect,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  // height: 60px;
  background-color: #fff;
  justify-content: space-between;
  color: #666;
  width: 100%;
  overflow: hidden;
  border-bottom: 2px solid #eee;
  .el-menu-item{
      font-size: 18px;
      width: 160px;
      border: none;
      &.is-active{
          border: none;
          position: relative;
          &::after{
              content: ' ';
              display: block;
              position: absolute;
              left: 50%;
              transform: translate(-50%);
              bottom: 8px;
              width: 52px;
              height: 6px;
              border-radius: 4px;
              background-color: $mainColor;
          }
      }
  }
  aside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    &:nth-of-type(2){
        width: 300px;
    }
  }
}
</style>
