import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import App from './App.vue'
import router from './router'
import axios from './axios'
import store from './store'
import Element from 'element-ui'
import dayjs from 'dayjs'
import dialog from '@/components/common/GoDialog'
import godialog from '@/components/commonUI/Dialog'
import Breadcrumb from '@/components/commonUI/Breadcrumb'
import DragTable from "@/components/common/DragTable.vue";
import ChildWindow from '@/components/commonUI/ChildWindow'

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element)
Vue.use(VueCompositionApi)

const dialogBox = Vue.extend(dialog)
const dialogBoxInstance = new dialogBox().$mount()
document.body.appendChild(dialogBoxInstance.$el)
const dialogBoxCtl = text=>{
  dialogBoxInstance.open(text)
  return new Promise(res=>{
    dialogBoxInstance.callback = res
  })
}
Vue.component('go-dialog', godialog)
Vue.component('breadcrumb',Breadcrumb)
Vue.component('DragTable',DragTable)
Vue.component('child-window', ChildWindow)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$goDialog = dialogBoxCtl
Vue.prototype.$dayjs = dayjs
let timer = null
Vue.prototype.debounce = (func, time) => {
  if(timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(func, time)
}

router.beforeEach((to,from,next)=>{
  const token = window.localStorage.getItem('loginToken')
  if(to.path.includes('login')) {
    next()
    return
  }
  if(token) {
    next()
  } else {
    next({path:'/login'})
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
