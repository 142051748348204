<template>
  <div>
    <div class="header">
      <img class="logo" src="../assets/images/logo.png" />
    </div>
    <el-menu
      :unique-opened="true"
      :default-active="activeIndex"
      text-color="#333"
      active-text-color="#4F5BFF"
      @select="handleSelect"
    >
      <div v-for="(item) in list" :key="item.name">
        <el-submenu v-if="item.children && !item.hideThis" :index="item.name">
          <template slot="title"
            ><img v-if="item.icon" class="icon" :src="`${menuImgIndex == item.name ? item.icon+'(1)' : item.icon}.png`" /><span class="span">{{ item.label }}</span></template
          >
          <div v-for="(data) in item.children" :key="data.name">
            <el-menu-item v-if="!data.hideThis" :index="data.name">{{
              data.label
            }}</el-menu-item
            >
          </div>
          <!-- <el-menu-item index="vajra">金刚位</el-menu-item>
        <el-menu-item index="site">站点入口</el-menu-item>
        <el-menu-item index="specialSubject">专题</el-menu-item> -->
        </el-submenu>
        <el-menu-item
          v-else-if="!item.children && !item.hideThis"
          :index="item.name"
          ><img v-if="item.icon" class="icon" :src="`${activeIndex == item.name ? item.icon+'(1)' : item.icon}.png`">{{ item.label }}</el-menu-item
        >
      </div>
    </el-menu>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "@vue/composition-api";
import sider from "@/assets/js/sider.js";
import rouetList from "@/router/router";

export default defineComponent({
  setup(props, { root }) {
    const activeIndex = ref(root.$route.name);
    const listIndex = ref(0);
    const list = ref([]);
    const menuImgIndex = ref()
    const changeRouter = (name) => {
      rouetList[0].children.some((item, sortId) => {
        (item.children || []).some((ite) => {
          if (ite.name === name) {
            listIndex.value = sortId;
            menuImgIndex.value = ite.name
            root.$store.commit('topIndex', item.name)
            root.$store.dispatch("setbreadcrumb", {
              label: item.label,
              index: item.index,
            });
            root.$store.dispatch("setbreadcrumb", {
              label: ite.label,
              index: ite.index,
            });
            if (ite.path.includes("articleDetail")) {
              root.$store.dispatch("setbreadcrumb", {
                label: root.$route.query.id ? "编辑文章" : "新建文章",
                index: 2,
              });
            }
            if(ite.name === 'risk_words_detail') {
              root.$store.dispatch("setbreadcrumb", {
                label: '词库详情',
                index: 2,
              });
            }
            if(ite.name !== root.$route.name) {
              root.$router.push(ite.path)
            }
            return true;
          }
          const third = (ite.children || []).find((it) => it.name === name);
          if (!third) return;
          listIndex.value = sortId;
          menuImgIndex.value = ite.name
          root.$store.commit('topIndex', item.name)
          root.$store.dispatch("setbreadcrumb", {
            label: item.label,
            index: item.index,
          });
          root.$store.dispatch("setbreadcrumb", {
            label: ite.label,
            index: ite.index,
          });
          root.$store.dispatch("setbreadcrumb", {
            label: third.label,
            index: third.index,
          });
          if(third.name !== root.$route.name) {
            root.$router.push(third.path)
          }
        });
      });
      list.value = rouetList[0].children[listIndex.value].children;
      // 不显示的三级分类在sider.js里添加映射
      activeIndex.value = sider[name] || name
    };
    changeRouter(root.$route.name)
    watch(
      () => root.$route.name,
      (val) => {
        root.$store.commit('childWindow', {show: false, data: {}})
        changeRouter(val);
      }
    );
    const handleSelect = (key) => {
      root.$router.push({name: key})
    };
    return {
      menuImgIndex,
      activeIndex,
      list,
      listIndex,
      handleSelect,
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin menuLine() {
  min-width: 0;
  border-radius: 0px 32px 32px 0px;
  &.is-active {
    background-color: rgba(231, 232, 246, 0.6) !important;
    position: relative;
    &::after {
      content: " ";
      display: block;
      width: 6px;
      height: 18px;
      background: rgba(79, 91, 255, 0.5);
      border-radius: 3px;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.header {
  background-color: #fff;
  padding: 8px 24px;
  border-bottom: 2px solid #eee;
  height: 60px;
  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.el-menu {
  background-color: $homeSiderColor;
  text-align: left;
  padding-right: 15px;
}
::v-deep .el-submenu {
  .el-menu {
    background: none;
  }
  &.is-active {
     .span{
      color: $mainColor;
    }
    .el-menu-item {
      @include menuLine();
      &.is-active {
        &::after {
          left: 20px;
        }
      }
    }
  }
}
.icon {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-right: 5px;
  display: inline-block;
}
::v-deep .el-submenu__title {
  display: flex !important;
  align-items: center !important;
}
.el-menu-item {
  display: flex !important;
  align-items: center !important;
}
</style>
