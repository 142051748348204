import Index from '@/views/marketCenter/Index'
import CouponChild from '@/views/marketCenter/couponManager/coupon/CouponChild'

export default [{
  path: '/market',
  name: 'market',
  label: '营销中心',
  index: 0,
  redirect: '/market/couponManager',
  component: Index,
  children: [{
    path: '/market/couponManager',
    name: 'couponManager',
    icon: '/images/icon/icon7',
    label: '优惠券管理',
    index: 1,
    redirect: '/market/couponManager/coupon',
    component: ()=> import(`@/views/marketCenter/couponManager/Index`),
    children: [
      {
        path: '/market/couponManager/coupon',
        name: 'coupon',
        label: '优惠券',
        index: 2,
        component: ()=> import(`@/views/marketCenter/couponManager/coupon/Coupon.vue`)
      },{
        path: '/market/couponManager/CouponChild',
        name: 'CouponChild',
        label: '优惠券详情',
        index: 2,
        hideThis: true,
        component: CouponChild
      },{
        path: '/market/couponManager/code',
        name: 'code',
        label: '兑换码',
        index: 2,
        component: ()=> import(`@/views/marketCenter/couponManager/code/Index`)
      },{
        path: '/market/couponManager/codeDetail',
        name: 'codeDetail',
        label: '兑换码详情',
        hideThis: true,
        index: 2,
        component: ()=> import(`@/views/marketCenter/couponManager/code/Detail`)
      }
    ]
  },{
    path: '/market/goodsManager',
    name: 'goodsManager',
    label: '商品管理',
    index: 1,
    redirect: '/market/goodsManager/non_analysis',
    component: ()=> import(`@/views/marketCenter/goodsManager/Index`),
    children: [
      {
        path: '/market/goodsManager/non_analysis',
        name: 'goodsManager_non_analysis',
        label: '非解析站商品直达配置',
        index: 2,
        component: ()=> import(`@/views/marketCenter/goodsManager/non_analysis.vue`)
      },
      {
        path: '/market/goodsManager/non_analysis_detail',
        name: 'goodsManager_non_analysis_detail',
        label: '商品详情',
        index: 2,
        hideThis: true,
        component: ()=> import(`@/views/marketCenter/goodsManager/goods_detail.vue`)
      }
    ]
  },{
    path: '/market/activity_manager',
    name: 'activity_manager',
    label: '活动管理',
    index: 1,
    redirect: '/market/activity_manager/list',
    component: ()=> import(`@/views/marketCenter/activity_manager/index`),
    children: [
      {
        path: '/market/activity_manager/list',
        name: 'activity_manager_list',
        label: '固定活动配置',
        index: 2,
        component: ()=> import(`@/views/marketCenter/activity_manager/options/list.vue`)
      }
    ]
  },
]
}]