<template>
  <div>
    <div class="login">
      <div class="left">
        <img src="@/assets/images/logo.png" />
      </div>
      <div class="right">
        <h3>登录<img src="@/assets/images/logo2.png" /></h3>
        <el-input v-model="userName" placeholder="请输入账号"></el-input>
        <el-input style="margin-top:23px;" v-model="password" type="password" placeholder="请输入密码" @keyup.enter.native="login"></el-input>
        <footer class="footer">
          <el-button type="primary" @click="login">登录</el-button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  setup(props, {root}) {
    const userName = ref('')
    const password = ref('')
    const login=()=>{
      if(!userName.value) {
        root.$message.warning('请输入用户名')
        return
      }
      if(!password.value) {
        root.$message.warning('请输入密码')
        return
      }
      root.$axios.post(`/login`,{
        username: userName.value,
        password: password.value
      })
      .then(res=>{
        window.localStorage.setItem('loginToken', res.data.token)
        window.localStorage.setItem('userName', res.data.username)
        root.$store.commit('setUserName', res.data.username)
        root.$message.success(res.msg)
        root.$router.push('/cms')
      })
    }

    return {
      userName,
      password,
      login
    }
  },
})
</script>
<style lang="scss" scoped>
.login{
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  width: 664px;
  height: 393px;
  display: flex;
  .left{
    flex-shrink: 0;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 100%;
    }
  }
  .right{
    padding: 0 50px;
    h3{
      font-size: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 46px;
      margin-top: 60px;
      img{
        height: 30px;
      }
    }
    .el-input{
      width: 250px;
    }
  }
}
.footer{
  margin-top: 24px;
  button{
    width: 250px;
  }
}
</style>