import Index from '@/views/user_center/Index'
import cancellation from '@/views/user_center/cancellation/list'

export default [{
  path: '/userCenter',
  name: 'userCenter',
  label: '用户中心',
  index: 0,
  redirect: '/userCenter/cancellation',
  component: Index,
  children: [
    {
      path: '/userCenter/cancellation',
      name: 'userCenter_cancellation',
      label: '用户注销管理',
      index: 1,
      component: cancellation,
    },
  ]
}]