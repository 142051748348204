import Index from '@/views/CMS/Index'
import IndexManager from '@/views/CMS/indexManager/Index'
export default [{
  path: '/CMS',
  name: 'CMS',
  label: 'CMS系统',
  index: 0,
  component: Index,
  redirect: '/CMS/indexManager',
  children: [
    {
      path: '/CMS/indexManager',
      name: 'IndexManager',
      icon: '/images/icon/icon5',
      label: '首页模块管理',
      index: 1,
      redirect: '/CMS/indexManager/banner',
      component: IndexManager,
      children: [{
        path: '/CMS/indexManager/banner',
        name: 'banner',
        label: 'banner',
        index: 2,
        component: () => import('@/views/CMS/indexManager/bannerManage/Banner')
      },
      {
        path: '/CMS/indexManager/specialSubject',
        name: 'specialSubject',
        label: '专题',
        index: 2,
        component: () => import(`@/views/CMS/indexManager/specialSubject/Index`)
      },
      {
        path: '/CMS/indexManager/site',
        name: 'CMS_site',
        label: '站点入口',
        index: 2,
        component: () => import(`@/views/CMS/indexManager/site/Index`)
      },
      {
        path: '/CMS/indexManager/vajra',
        name: 'vajra',
        label: '金刚位',
        index: 2,
        component: () => import(`@/views/CMS/indexManager/bannerBottom/Index`)
      },{
        path: '/CMS/indexManager/home_category',
        name: 'CMS_home_category',
        label: '商品瀑布流',
        index: 2,
        component: ()=> import(`@/views/CMS/indexManager/siteCategory/home.vue`)
      },{
          path: '/CMS/indexManager/site_category',
          name: 'CMS_site_category',
          label: '站点页商品瀑布流',
          index: 2,
          component: ()=> import(`@/views/CMS/indexManager/siteCategory/Index.vue`)
        }
      ]
    },
    {
      path: '/CMS/article',
      name: 'article',
      icon: '/images/icon/icon3',
      label: '文章管理',
      index: 1,
      component: () => import(`@/views/CMS/articleManager/Index`)
    }, {
      path: '/CMS/articleDetail',
      name: 'articleDetail',
      label: '文章管理',
      hideThis: true,
      index: 1,
      component: () => import(`@/views/CMS/articleManager/Edit`)
    }, {
      path: '/CMS/category',
      name: 'category_goods_manager',
      label: '商品分类管理',
      icon: '/images/icon/icon7',
      index: 1,
      component: () => import(`@/views/CMS/categoryManager/index`),
      redirect: '/CMS/category/goods',
      children: [{
        path: '/CMS/category/goods',
        name: 'category_goods',
        label: '商品分类',
        index: 2,
        component: () => import(`@/views/CMS/categoryManager/goodsManager/site.vue`),
      },{
        path: '/CMS/category/map',
        name: 'category_map',
        label: '分类映射',
        index: 2,
        component: () => import(`@/views/CMS/categoryManager/map/index.vue`),
      },{
        path: '/CMS/category/search',
        name: 'category_search',
        label: '搜索分类',
        index: 2,
        component: () => import(`@/views/CMS/categoryManager/search/index.vue`),
      }]
    }, {
      path: '/CMS/siteManager',
      name: 'site',
      label: '站点管理',
      icon: '/images/icon/icon7',
      index: 1,
      component: () => import(`@/views/CMS/categoryManager/index`),
      redirect: '/CMS/site/nav',
      children: [{
        path: '/CMS/site/nav',
        name: 'site_nav',
        label: '站点导航',
        index: 2,
        component: () => import(`@/views/CMS/siteManager/index.vue`),
      }]
    }, {
      path: '/CMS/searchManager',
      name: 'search',
      label: '搜索管理',
      icon: '/images/icon/icon7',
      index: 1,
      component: () => import(`@/views/CMS/searchManager/index`),
      redirect: '/CMS/search/category',
      children: [{
        path: '/CMS/search/category',
        name: 'search_category',
        label: '站点搜索分类',
        index: 2,
        component: () => import(`@/views/CMS/searchManager/siteSearch.vue`),
      }
      // ,{
      //   path: '/CMS/search/hot',
      //   name: 'search_hot',
      //   label: '热门搜索词',
      //   index: 2,
      //   component: () => import(`@/views/CMS/searchManager/hot.vue`),
      // }
    ]
    }
  ]
}
]
