import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const CMS = {
  state: {
    url: process.env.VUE_APP_API_URL,
    userName: '',
    breadcrumb: [],
    topIndex: ''
  },
  mutations: {
    updateUrl(state,data) {
      state.url = data
    },
    setbreadcrumb(state, data) {
      state.breadcrumb = JSON.parse(JSON.stringify(data))
      window.document.title = state.breadcrumb[state.breadcrumb.length-1].label
    },
    setUserName(state, data) {
      state.userName = data
    },
    topIndex(state,data) {
      state.topIndex = data
    }
  },
  actions: {
    setbreadcrumb({ commit, state }, data) {
      let arr = state.breadcrumb
      if(arr.length === 0) {
        arr = Array.from({length: data.index+1})
      } else if(data.index > arr.length) {
        const num = data.index - arr.length + 1
        arr = arr.concat(Array.from({length: num}))
      } else if( arr.length > 2 && arr.length > data.index+1) {
        arr.splice(data.index+1, arr.length-data.index-1)
      }
      arr[data.index] = data
      commit('setbreadcrumb', arr)
    }
  }
}
const common = {
  state: {
    childWindow: JSON.parse(localStorage.getItem('childWindow')) || {show: false, data: {}}
  },
  mutations: {
    childWindow(state,data) {
      state.childWindow = data
      localStorage.setItem('childWindow', JSON.stringify(data))
    },
  },
}

export default new Vuex.Store({
  state: {
    breadcrumb_show: true
  },
  modules: {
    CMS,
    common
  }
})
